<template>
    <div class="compose-message-page">
       <!--  {{ this.custom_settings }} -->
        <div class="white-bg-container">
            <form @submit.prevent="submitFormData" class="mb-0">

                <div v-if="error" class="alert alert-dismissible alert-warning">
                    <button type="button" class="close" data-dismiss="alert">&times;</button>
                    <h4 class="alert-heading">Error!</h4>
                    <p class="mb-0">{{error}}</p>
                </div>

                <!-- Block added to show the Auth Error. -->
                <!--
                    Block added to show the Auth Error.
                    <div cols="12" sm="6" md="6" v-if="serverError">
                        <div class="servererror">{{serverError}}</div>
                    </div> 
                -->
                

                <!-- <div class="col-12" v-if="success">
                    <button @click.prevent="reset" class="btn btn-primary mt-3">Add new Content</button>
                    <router-link :to="{ name: 'ourcontents' }" class="btn btn-primary mt-3 ml-2">View Contents List</router-link>
                </div> -->
<!-- 
                <div class="col-12" v-if="success">
                    <v-btn :ripple="false" @click="reset" small depressed color="primary"> Add new Content </v-btn>&nbsp;
                    <v-btn :ripple="false" @click="$router.push('/ourcontents')" small depressed color="primary">View Contents List</v-btn>
                    
                </div> -->

                
                <div class="form-elements" >
                    <div class="map-section">
                        <div class="form-row justify-content-between">
                            <div class="form-control-con d-flex">
                                <label for="locaiton" class="form-label">Set Location</label>
                                <input hidden
                                    style="background-color:rgb(221 221 221)"
                                    v-model="content.Lat"
                                    class="form-control "
                                    id="Lat"
                                    v-on:keydown="locationkeydown"
                                    ref="Lat"
                                    placeholder="Select the location">
                            </div>
                            <!-- <div class="form-control-con col-4 ps-sm-3 p-0"> -->
                            <div class="col-4 ps-sm-3 p-0 d-flex">
                                <label for="radius" class="form-label">Set Radius</label>
                               
                                <input hidden 
                                    v-model="content.Radius"
                                    class="form-control"
                                    id="Radius"
                                    name="Radius"
                                    placeholder="Radius">
                            </div>
                        </div>
                        <div class="row" style="margin-top:0px" v-if="content.Radius_Unit == 1"> 
                            <AddGoogleMap class="col-sm-11" style="padding-top:0px;" v-on:latlongupdated="latlongupdated" v-on:radiusupdated="radiusupdated" v-bind:langlat="content.Lat" v-bind:_radius="radiusComputed"/>
                        </div>
                        <div class="row" style="margin-top:0px" v-if="content.Radius_Unit == 2"> 
                            <AddGoogleMapKM class="col-sm-11" style="padding-top:0px;" v-on:latlongupdated="latlongupdated" v-on:radiusupdated="radiusupdated" v-bind:langlat="content.Lat" v-bind:_radius="radiusComputed"/>
                        </div>
                        
                    </div>
                    <div class="message-section">
                        <div class="section-label-con d-flex align-items-center justify-content-between position-relative">
                            <label class="form-label m-0">Compose</label>
                            <div v-if="content.Mode == 1" class="content-mode">
                                <!-- <span v-if="isSavingNow == 1">Saving <v-icon size="20px"  title="In draft mode">mdi-autorenew</v-icon></span>
                                <span v-if="isSavingNow == 0">Saved <v-icon size="20px"  title="In draft mode">mdi-cloud-check-outline</v-icon></span>
                                <span v-if="isSavingNow == 2">Error in Saving <v-icon size="20px" color="red" title="In draft mode">mdi-cloud-alert-outline</v-icon></span> -->
                                <span v-if="isSavingNow == 1">
                                    <span class="custom-icon-text"> Saving </span>
                                    <span class="custom-icon"> 
                                        <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 4.41171L7.07841 8.4364L11.1031 7.35799" stroke="#7B8794" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M23.8298 15.44L23.1063 11.3366L19.0029 12.0602" stroke="#7B8794" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M23.4325 11.9949C23.6138 11.5719 23.4178 11.082 22.9948 10.9007C22.5717 10.7194 22.0818 10.9153 21.9005 11.3384L23.4325 11.9949ZM13.5065 17.3131L13.6905 16.5003L13.6905 16.5003L13.5065 17.3131ZM8.87691 12.222C8.72337 11.7881 8.24718 11.5608 7.81331 11.7144C7.37944 11.8679 7.15218 12.3441 7.30571 12.778L8.87691 12.222ZM21.9005 7.83252C22.0818 8.25554 22.5717 8.4515 22.9948 8.27021C23.4178 8.08891 23.6138 7.59901 23.4325 7.17599L21.9005 7.83252ZM13.5065 1.8578L13.6905 2.67059L13.6905 2.67059L13.5065 1.8578ZM7.30571 6.39292C7.15218 6.8268 7.37944 7.30298 7.81331 7.45652C8.24718 7.61005 8.72337 7.38279 8.87691 6.94892L7.30571 6.39292ZM21.9005 11.3384C20.7173 14.0994 19.1885 15.4785 17.7471 16.1314C16.2903 16.7912 14.8194 16.7557 13.6905 16.5003L13.3226 18.1259C14.7054 18.4387 16.5645 18.4967 18.4347 17.6496C20.3204 16.7955 22.1157 15.0672 23.4325 11.9949L21.9005 11.3384ZM13.6905 16.5003C11.4578 15.9951 9.64052 14.3799 8.87691 12.222L7.30571 12.778C8.26023 15.4754 10.5319 17.4944 13.3226 18.1259L13.6905 16.5003ZM23.4325 7.17599C22.1157 4.10365 20.3204 2.37541 18.4347 1.52132C16.5645 0.67419 14.7054 0.732143 13.3226 1.04502L13.6905 2.67059C14.8194 2.41516 16.2903 2.37967 17.7471 3.03951C19.1885 3.69238 20.7173 5.07152 21.9005 7.83252L23.4325 7.17599ZM13.3226 1.04502C10.5319 1.67646 8.26023 3.69553 7.30571 6.39292L8.87691 6.94892C9.64052 4.791 11.4578 3.17575 13.6905 2.67059L13.3226 1.04502Z" fill="#7B8794"/>
                                        </svg>

                                    </span>
                                </span>
                                <span v-if="isSavingNow == 0">
                                    <span class="custom-icon-text"> {{ saveStatus }} </span>
                                    <span class="custom-icon"> 
                                        <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1783_1732)">
                                                <path d="M18.7657 1.03638C14.0073 0.245474 9.41627 3.15456 8.20809 7.72729H6.74898C3.54266 7.72729 0.94043 10.2727 0.94043 13.4091C0.94043 16.5455 3.54266 19.0909 6.74898 19.0909H23.2639C26.4702 19.0909 29.0724 16.5455 29.0724 13.4091C29.0724 11.3909 27.9943 9.62729 26.3865 8.6182" stroke="#7B8794" stroke-width="1.81819" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M27.4457 1.05457L17.2227 11.0546L14.4346 8.32729" stroke="#7B8794" stroke-width="1.81819" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1783_1732">
                                                    <rect width="30" height="20" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </span>
                                </span>
                            </div>
                            <div v-if="content.Mode == 2" class="content-mode">
                                <span>
                                    <!-- <span class="custom-icon-text"> Saving </span> -->
                                    <span class="custom-icon"> 
                                        <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_1783_1732)">
                                                <path d="M18.7657 1.03638C14.0073 0.245474 9.41627 3.15456 8.20809 7.72729H6.74898C3.54266 7.72729 0.94043 10.2727 0.94043 13.4091C0.94043 16.5455 3.54266 19.0909 6.74898 19.0909H23.2639C26.4702 19.0909 29.0724 16.5455 29.0724 13.4091C29.0724 11.3909 27.9943 9.62729 26.3865 8.6182" stroke="#7B8794" stroke-width="1.81819" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M27.4457 1.05457L17.2227 11.0546L14.4346 8.32729" stroke="#7B8794" stroke-width="1.81819" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1783_1732">
                                                    <rect width="30" height="20" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="" id="english_text" v-if="content.Locale == 'English'">

                            <div class="form-group">
                                <label for="Title" class="form-label">Title</label>
                                <input v-on:keydown="contentkeydown" type="text" name="Title" v-model="content.Title" class="form-control" required>
                            </div>
                            
                            <div class="form-group align-items-start" style="margin-bottom: 70px;">
                                <label for="Body" class="form-label mt-3">Message</label>
                                <div class="position-relative w-100">
                                    <textarea
                                    v-on:keydown="contentkeydown"
                                    v-model="content.Body"
                                    class="form-control message-body"
                                    id="Body"
                                    rows="10"
                                    placeholder=""></textarea>
                                    <div class="word-count-text"> {{wordCountText }}</div>
                                </div>
                            </div>
                             

                            <div class="form-group row translation-link my-4 mt-4"  v-if="content.spn_Title && content.spn_Body">
                                <label for="Locale" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4 p-0 pt-1">
                                    <!-- <a href="" @click.prevent="content.Locale = 'Spanish'; onLocaleChange({value: 'Spanish'});">See Spanish translation</a> -->
                                    <span class="disabled">See Spanish translation</span>
                                </div>
                            </div> 
                            

                            <!-- SMS notification success / fail need to be designed-->
                            <!-- <div class="row" v-if="smsnotification"  style="text-align:right" @click="handleClick">
                                <div class="col-sm-12" style="padding:0px 35px 15px 0px; font-size:12px;" v-html="smsnotification"></div>
                                <span style="position: absolute; right: 20px; cursor:pointer" @click="smsnotification = ''">X</span>
                            </div> -->



                            <MessageSettings @setting-saved="onSettingSaved" :duration_basic="content.Expiration_Timestamp" :custom_settings="custom_settings" :openonload="showSettingsOnLoad"></MessageSettings>
                               
                                
                            <div class="form-group form-duration-con align-items-start">
                            <!-- <div class="form-group form-duration-con align-items-start"> -->
                                <div class="col-12 col-sm-6 p-0">
                                    <div class="form-group" style="margin-bottom: 43px;">
                                        <!-- Duration -->
                                        <label for="Locale" class="form-label">Duration</label>
                                        <div class="form-control static-control">{{ content.Expiration_Duration }}</div>
                                        <!-- <Dropdown class="w-100" :disabled="true" :items="durationObj" :value="content.Expiration_Duration" @item-selected="onDurationSelected"/> -->
                                    </div>
    
                                    <div class="form-group mb-0">
                                        <label for="Locale" class="form-label mb-0" style="opacity:.4;">Translate</label>
                                        <Dropdown style="pointer-events: none; opacity:.4;" class="w-100" :disabled="true" :items="languageObj" :value="content.Locale" @item-selected="onLocaleChange"  />
                                    </div>
    
                                </div>
                                <div class="col-12 col-sm-6 p-0 text-end preview-btn-con">
                                    <div class="preview-button-wrapper">
                                        <span :class="{'disabled-text': !phonePreviewActive || !content.ContentID}">Phone Preview</span>
                                        <div class="preview-btn-space">
                                            <button :disabled="!phonePreviewActive || !content.ContentID" @click.prevent="textToSpeech('Body')" plain class="preview-btn">
                                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z" fill="currentColor"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M40.4047 28.3633L26.871 20.2565C26.282 19.9164 25.5568 19.9144 24.9659 20.2512C24.375 20.588 24.0072 21.2131 23.9997 21.8932V38.1068C24.0072 38.7869 24.375 39.412 24.9659 39.7488C25.5568 40.0856 26.282 40.0836 26.871 39.7435L40.4047 31.6367C40.9768 31.2897 41.3262 30.6691 41.3262 30C41.3262 29.3309 40.9768 28.7103 40.4047 28.3633Z" fill="white"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="" id="spanish_text" v-if="content.Locale == 'Spanish'">

                            <div class="form-group">
                                <label for="spn_Title" class="form-label">Title</label>
                                <input type="text" name="spn_Title" v-model="content.spn_Title" class="form-control" required>
                            </div>

                            <div class="form-group align-items-start mb-0">
                                <label for="spn_Body" class="form-label mt-3">Message</label>
                                <textarea
                                v-model="content.spn_Body"
                                class="form-control message-body"
                                id="spn_Body"
                                rows="10"
                                placeholder=""></textarea>
                                <span>{{ wordCountText }}</span>
                             </div>

                            <div class="form-group row translation-link my-4 mt-4">
                                <label for="Locale" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-4 p-0 pt-1">
                                    <!-- <a href="" class="disabled-link" @click.prevent="content.Locale = 'English'">Show original version</a> -->
                                    <a>Show original version</a>
                                </div>
                            </div> 
                            <!-- <div class="form-group form-duration-con align-items-start"> -->
                            <div class="form-group form-duration-con align-items-start">
                                <div class="col-sm-6 p-0">
                                    <div class="form-group">
                                        <!-- Duration -->
                                        <label for="Locale" class="form-label">Duration</label>
                                        <Dropdown class="w-100" :items="durationObj" :value="content.Expiration_Duration" @item-selected="content.Expiration_Duration = $event.label" />
                                        
                                    </div>
    
                                    <div class="form-group mb-0">
                                        <label for="Locale" class="form-label mb-0">Translate</label>
                                        <Dropdown class="w-100" :items="languageObj" :value="content.Locale" @item-selected="onLocaleChange"  />
                                    </div>
    
                                </div>
                                
                                <div class="col-12 col-sm-6 p-0 text-end preview-btn-con">
                                    <div class="preview-button-wrapper">
                                        <span :class="{'disabled-text': !phonePreviewActive || !content.ContentID}">Phone Preview</span>
                                        <div class="preview-btn-space">
                                            <button :disabled="!phonePreviewActive || !content.ContentID" @click.prevent="textToSpeech('Body')" plain class="preview-btn">
                                                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30 55C43.8071 55 55 43.8071 55 30C55 16.1929 43.8071 5 30 5C16.1929 5 5 16.1929 5 30C5 43.8071 16.1929 55 30 55Z" fill="currentColor"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M40.4047 28.3633L26.871 20.2565C26.282 19.9164 25.5568 19.9144 24.9659 20.2512C24.375 20.588 24.0072 21.2131 23.9997 21.8932V38.1068C24.0072 38.7869 24.375 39.412 24.9659 39.7488C25.5568 40.0856 26.282 40.0836 26.871 39.7435L40.4047 31.6367C40.9768 31.2897 41.3262 30.6691 41.3262 30C41.3262 29.3309 40.9768 28.7103 40.4047 28.3633Z" fill="white"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                         <!-- SMS notification success / fail need to be designed-->
                         <div class="sms-success-notificaiton" 
                        @click="handleClick"  
                        v-html="smsnotification"></div>

                    </div>
                </div>

                
                <div class="form-footer-con">
                    <div  v-if="!success" class="selected-group-names">
                        <b>Heard by:</b> {{ selectedGroups.names }}
                    </div>
                    <div v-if="success" class="alert alert-dismissible alert-success">
                        <!-- <button type="button" class="close" data-dismiss="alert">&times;</button> -->
                        <span class="alert-heading">Success</span><br>
                        {{success}}
                    </div>
                    
                    <div class="col-12 col-sm-6 p-0 flex-between">
                        <MessageGroups @group-selection-saved="onGroupSelectionSaved"></MessageGroups>
                        <div class="form-btns-con">
                            <button type="button" plain @click="$router.push('/messages')" class=" close-btn" :disabled="success != ''"> Cancel </button>
                            <button type="submit" small depressed color="primary" class="btn btn-submit" :disabled="success != ''">{{ !edit /* || content.Mode == 1 */ ? 'SAVE MESSAGE' : 'UPDATE MESSAGE' }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>     
 
        <UpdateProfile 
            v-if="popupTriggers.buttonTrigger" 
            :TogglePopup="() => TogglePopup('buttonTrigger')" :ProfileSaved="(data) => ProfileUpdated()"
            :Notification="'Please enter your phone number to receive the Preview. You will receive an SMS with a link. Tap the link.'" >
        </UpdateProfile>
        <ErrorModal v-model="showErrorModal" :smsErrorNotification="smsErrorNotification"/>


        
 
    </div>


   

</template>

<script>
import AddGoogleMap from '../../components/AddGoogleMap';
import AddGoogleMapKM from '../../components/AddGoogleMapKM';
import MessageSettings from '../../components/MessageSettings';
import MessageGroups from '../../components/MessageGroups';
import { ref } from 'vue';
/* import { VBtn} from 'vuetify/lib'; */
import UpdateProfile from '../../components/UpdateProfile';
import Dropdown from '../../components/common/Dropdown.vue'
import ErrorModal from '../../components/modals/ErrorModal.vue';
//import { VRangeSlider } from "vuetify"
//let interval;
//let kmORmile = 2;
export default {
    setup () {
		const popupTriggers = ref({
			buttonTrigger: false
		});		
        const TogglePopup = (trigger) => {
			popupTriggers.value[trigger] = !popupTriggers.value[trigger]
		}
        return {
			UpdateProfile,
			popupTriggers,
			TogglePopup,
		}
	},
    name: 'ComposeMessage',
    components: {AddGoogleMap, AddGoogleMapKM, /* VBtn, */ UpdateProfile, Dropdown, ErrorModal, MessageSettings, MessageGroups},
    mounted(){
      this.custom_settings.Duration = this.content.Expiration_Duration;
      this.custom_settings.TimeStart = this.formatAMPM(this.getStartTimeForNewMessage());
      setTimeout(n=> this.messageWordCount(), 1000);
    },
    data: ()=>({
        interval:0,
        saveStatus:'',
        dialog: false,
        isSavingNow:0,
        edit : 0,
        error: "",
        success: "",
        phonePreviewActive: true,
        smsnotification: "",
        smsErrorNotification: "",
        savingDraft: false,
        serverError: "",
        showSettingsOnLoad: false,
        token: localStorage.getItem("tknds") || '',
        wordCountText:"",
        //userRadiusPreference: 1,
        custom_settings:{
            "Duration" : "3 Days",
            "StartDate" : new Date().toString(),
            "EndDate" : new Date().toString(),
            "RepeatOption" : "No",
            "RepeatDays": "1,1,1,1,1,1,1",
            "TimeStart" : "12:00 AM",
            "TimeEnd" : '11:59 PM',
            "When": "Arriving",
            "defaultSettings" : true
        },
        content: {
            //"ContentID":"",
            "Title": "",
            "Lat": "",
            "Long" : "",
            "Location" : "",
            "Search_Meta" : "",
            "Body": "",
            "spn_ContentID": "",
            "spn_ParentID": "",
            "spn_Title": "",
            "spn_Body": "",
            "Locale": "English",
            "Radius_Unit" : 1,
            "Radius":"",
            "Mode":0,
            "ClientID" : "",
            "Expiration_Duration" : "1 Day",
            "Expiration_Timestamp" : "0"
        },
        durationObj : [
           // { 'ms':'43200000'     , 'label':'1/2 Day' },
            { 'ms':'1'     , 'label':'1 Day' },
            { 'ms':'86400000'    , 'label':'2 Days' },
            { 'ms':'172800000'    , 'label':'3 Days' },
            { 'ms':'604800000'    , 'label':'1 Week' },
            { 'ms':'1209600000'    , 'label':'2 Week' },
            { 'ms':'1814400000'    , 'label':'3 Week' },
            { 'ms':'0'            , 'label':'No Limit'}
        ],
        languageObj: [
            { label: 'English', value:"English" },
            { label: 'Add Spanish', value:"Spanish" },
        ],
        showErrorModal: false,
        seasons: [
        '12:00 am',
        '','','','','','','','','','','','','','','','','','','','','','','',
        '6:00 am',
        '','','','','','','','','','','','','','','','','','','','','','','',
        '12:00 pm',
        '','','','','','','','','','','','','','','','','','','','','','','',
        '06:00 pm',
        '','','','','','','','','','','','','','','','','','','','','','','',
        '11:59 pm',
        
      ],
      icons: [
        'mdi-snowflake',
        'mdi-leaf',
        'mdi-fire',
        'mdi-water',
      ],
      selectedGroups:{
        names:"",
        ids:"",
        all:"0"
      }
    }),
    created(){
        this.content.Radius_Unit = localStorage.getItem("operator_radius_unit");
        this.content.Radius = localStorage.getItem("operator_radius");
        this.getExpirationTimestamp();
    },
    computed :{
        radiusComputed : function(){
            let val = this.content.Radius_Unit == 1 ? Number(this.content.Radius / 1609.344) : Number(this.content.Radius / 1000);
            //round up to 2 decimal
            return Math.round(val * 100) / 100;
        }
    },
    methods:{
        submitFormData(){
            //Change the content mode to 2 (published) when the save or update button is clicked
            this.content.Mode = 2;
            //console.log('Publishing: ', this.content);
            clearInterval(this.interval)
            this.addUpdateContent();
        },
        formatAMPM(mins) {
            let hours = "";
            let minutes = "";
            if (mins.indexOf(":") != -1){
                hours = mins.split(":")[0];
                minutes = mins.split(":")[1];
            }else {
                hours = Math.floor(mins / 60);
                minutes = mins % 60;
            }
            
            var ampm = hours < 12 ? 'AM' : 'PM';
            hours = hours > 12 ? hours - 12 : hours;
            
            /* if (hours === 12 && minutes === 0) {
                ampm = 'AM';
            } */
            if (hours == 0){
                hours = 12;
            }
            let timeString = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;
            return timeString;
        },
        roundToNearest15Minutes(date) {
            let minutes = date.getMinutes();
            let roundedMinutes = Math.ceil(minutes / 15) * 15;
            // Adjust the date object accordingly
            if (roundedMinutes === 60) {
                date.setHours(date.getHours() + 1);
                date.setMinutes(0);
            } else {
                date.setMinutes(roundedMinutes);
            }
            return date;
        },
        getStartTimeForNewMessage(){
            // Round off the current time to the nearest 15 minutes
            let roundedDate = this.roundToNearest15Minutes(new Date());
            // Format the rounded time as a string
            let hours = String(roundedDate.getHours()).padStart(2, '0');
            let minutes = String(roundedDate.getMinutes()).padStart(2, '0');
            if (roundedDate.getMinutes() == 0){
                 minutes = String(roundedDate.getMinutes()).padStart(1, '0');
            }
            return `${hours}:${minutes}`;
        },
        disabledClick(){
        },
        addUpdateContent(only_spn = false, redirect = true){
            this.content.actor = this.$root.loggedInUser;
            this.content.ClientID = this.$root.userdetail.user[0].ClientID;
            this.content.SenderID = this.$root.userdetail.user[0].UserID;
            //if (this.content.Expiration_Duration){}
            //this.content.Radius_Unit = this.userRadiusPreference;
            //this.content.Radius = this.content.Radius_Unit == 1 ? Math.round(Number(this.content.Radius * 1609.344)) : Math.round(Number(this.content.Radius * 1000));
            this.getExpirationTimestamp();

            

            if (this.content.Lat.indexOf(',') != -1){
                var tempVal = this.content.Lat;
                this.content.Lat = tempVal.split(',')[0];
                this.content.Long = tempVal.split(',')[1]; 
            }
            if (only_spn){
                this.content.only_spn = true;
            } else {
                delete this.content.only_spn;
            }
            let data = {
                "content" : this.content,
                "message_settings": this.custom_settings
            }

            console.log('Saving the data :: ', data)

            fetch(process.env.VUE_APP_APIDOMAIN+'/ourcontents', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer "+this.token
                }
            }).then(response => response.json())
                .then(result => {

                    if(result.AuthSuccess == 0){
                      this.serverError = result.message
                      this.$root.logout();
                        //   alert(this.serverError);
                        //   setTimeout(() => {
                        //     //console.log("NoData");
                        //     this.$router.push('/logout')
                        //   },2000);
                    }

                    //console.log(result)
                    if (result.details) {
                    // there was an error...
                        const error = result.details
                        //console.log(error)
                        this.isSavingNow = 2
                        .map(detail => detail.message)
                        .join(". ");
                        this.error = error;
                    } else {
                        //Reverse the Lat Long (concatinate with comma);
                        this.latlongupdated(this.content.Lat, this.content.Long);
                        this.error = "";
                        this.isSavingNow = 0;
                        this.saveStatus = 'Saved';
                        setTimeout(()=>{this.saveStatus = ''}, 1000);
                        if (only_spn){
                            this.content.spn_ContentID = result.insertId;
                            console.log('This is the result after update :', result)
                        }
                        
                        if (!only_spn && result.insertId){
                            console.log('result.insertId :: ', result.insertId);
                            this.content.ContentID = result.insertId;
                            console.log('this.content.ContentID :: ', this.content.ContentID);
                        }
                       
                        //if(this.selectedGroups.ids){
                        
                            //Save the groups selection
                            this.selectedGroups.message_id = this.content.ContentID;
                            fetch(process.env.VUE_APP_APIDOMAIN+'/savemessagegroups', {
                                method: "POST",
                                body: JSON.stringify(this.selectedGroups),
                                headers: {
                                    "content-type": "application/json",
                                    "Authorization": "Bearer "+this.token
                                }
                            }).then(response => response.json())
                                .then(result => {

                                    if(result.AuthSuccess == 0){
                                        this.serverError = result.message
                                        this.$root.logout();
                                    }

                                    if (result.details) {
                                        // there was an error...
                                        const error = result.details
                                        //console.log(error)
                                        this.isSavingNow = 2
                                        .map(detail => detail.message)
                                        .join(". ");
                                        this.error = error;
                                    } else {
                                        this.error = "";
                                        
                                    
                                        
                                    if (redirect) {
                                        this.success = (this.edit) ? "This Message is updated." : "This Message is set.";  
                                    // let ref = this;
                                    setTimeout(()=>{ this.$router.push('/messages')}, 2000)
                                    }              
                                }
                            });
                        
                        //save group selection end

                                     
                    }
            });
        },
        contentkeydown(e){
            this.phonePreviewActive = true;
            setTimeout(n=> this.messageWordCount(), 1000);
            if(this.edit || this.savingDraft || String(this.content.Title).trim() == "" || String(this.content.Body).trim() == ""){
                return;
            }
            let thisRef = this;
            this.savingDraft = true;
            this.startDraftMode()
            this.interval = setInterval(thisRef.startDraftMode, 8000);
        },
        messageWordCount(){
            this.wordCountText = "";
            if (this.content.Body.trim() != ""){
                const message = this.content.Body;
                const words = message.trim().split(/\s+/).length;
                const minutes = (words / 200).toFixed(2);
                const miles = ((words / 200) * 0.5).toFixed(2);
                this.wordCountText = `${words} words. ${Math.ceil(minutes*60)} seconds read time. ${Math.ceil(miles*1760)} yards traveled at 35 mph`;
            }
        },
        startDraftMode(){
            if (this.content.Mode == 2){
                clearInterval(this.interval);
            }
            this.content.Mode = 1;
            this.isSavingNow = 1;
            if(this.content.ContentID){
                this.addUpdateContent(false, false);
            } else {
                this.addUpdateContent(false, false);
            }

        },
        onLocaleChange(e){
            if (e.value != "undefined"){
                this.content.Locale = e.value;
            }
            if (!this.content.Title && !this.content.Body){
                alert('Please fill the English Title and Message First!');
                this.content.Locale = "English";
            } else {
                this.phonePreviewActive = true;
                if (this.content.Locale == "Spanish") {
                fetch(process.env.VUE_APP_APIDOMAIN+'/spanishtranslate', { 
                    method: 'POST' ,
                    body: JSON.stringify({title: this.content.Title, body: this.content.Body}),
                    headers: { 
                        "content-type": "application/json",
                        "Authorization": "Bearer "+this.token 
                    }
                }).then(response => response.json())
                .then((result) => {

                    if(result.AuthSuccess == 0){
                        this.serverError = result.message
                        this.$root.logout();
                        // alert(this.serverError);
                        // setTimeout(() => {
                        //     //console.log("NoData");
                        //     this.$router.push('/logout')
                        // },2000);							           
                    }

                    let translated = result.split('\n').join('').split('1. ').join(''); 
                    translated.split('2.');
                    this.content.spn_Title = translated.split('2.')[0];
                    this.languageObj[1] = { label: 'Spanish', value:"Spanish" }
                    if (typeof translated.split('2.')[1] != "undefined"){
                        this.content.spn_Body = 'the following message was generated with machine translation. If the content was difficult to understand or inappropriate, please report it as soon as it is safe to use your phone \n\n'+translated.split('2. ')[1];
                    }
                    //save the spanish version
                    //console.log("Spanish Translated-> content: ", this.content);
                    //if the spanish version is not saved yet, save it for the first time
                    if (!this.content.spn_ContentID){
                        this.addUpdateContent(true , false);
                    }
                })
                }
            }
        },
        onDurationSelected(data){
            this.content.Expiration_Duration = data.label; 
            this.custom_settings.Duration = data.label;
            this.getExpirationTimestamp()
        },
        getDateForSql(dateTime){
            // Format the Date object to the desired output
            let year = dateTime.getFullYear();
            let month = ('0' + (dateTime.getMonth() + 1)).slice(-2); // Months are zero-based
            let day = ('0' + dateTime.getDate()).slice(-2);
            let hours = ('0' + dateTime.getHours()).slice(-2);
            let minutes = ('0' + dateTime.getMinutes()).slice(-2);
            let seconds = ('0' + dateTime.getSeconds()).slice(-2);
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        getExpirationTimestamp(){
                if(this.content.Expiration_Duration == "No Limit"){
                    this.content.Expiration_Timestamp = "";
                } else {
                    let date = new Date(this.custom_settings.EndDate).toDateString(); 
                    this.content.Expiration_Timestamp = this.getDateForSql(new Date(`${date} ${this.custom_settings.TimeEnd}`));
                }
        },
        ProfileUpdated(){
            //this.TogglePopup('buttonTrigger');
            
            this.popupTriggers.buttonTrigger = false
            this.textToSpeech('Body', true);
            
        },
        textToSpeech(val, profileupdated=false){
            let contentID = (this.content.Locale == "English") ? this.content.ContentID : this.content.spn_ContentID;
            let previewURL = "https://listen.sig2.app/?id="+contentID;
            if(!this.$root.userdetail.user[0].PhoneNumber || String(this.$root.userdetail.user[0].PhoneNumber).trim() == ''){
                this.TogglePopup('buttonTrigger');
            } else {
                if (!profileupdated){
                    this.phonePreviewActive = false;
                }
            this.startDraftMode() 
            fetch(process.env.VUE_APP_APIDOMAIN+'/phonepreview', {
                method: "POST",
                body: JSON.stringify({"to":this.$root.userdetail.user[0].PhoneNumber, "message": "Sig2: Tap the link to listen to your message as your receivers will hear it. "+ previewURL, "ContentID" : contentID}),
                headers: { 
                    "content-type": "application/json",
                    "Authorization": "Bearer "+this.token 
                }
            }).then(response => response.json())
            .then((result)=>{

                if(result.AuthSuccess == 0){
                      this.serverError = result.message
                      this.$root.logout();

                    //   alert(this.serverError);
                    //   setTimeout(() => {
                    //     //console.log("NoData");
                    //     this.$router.push('/logout')
                    //   },2000);							           
                }
                if(result.smssent){
                this.smsnotification = "<span class='success-msg'>SMS sent to your phone.</span>";
                } else {
                    this.showErrorModal = true;
                    this.smsErrorNotification =  result.message;
                }
                setTimeout(() => {
                    this.smsnotification = ''
                }, 7000);
            }) 
            }
        },
        locationkeydown(e){
            e.preventDefault();
        },
        async latlongupdated(lat, lng){
            //alert('latlongupdated')
            this.content.Lat = lat +","+lng;
            let loc = await this.getAddress(lat, lng);
            this.content.Location = loc.Location;
            this.content.Search_Meta = loc.Search_Meta;
        },
        getAddress(lat, lon) {
            return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=AIzaSyAr5X7eblwPkDDuB9Xvw8NSsOtzYuXYUOQ`).then(result => result.json()).then(result => {
                if (result && result.results.length) {
                    let searchmetadata = '';
                    for(let a=0; a<result.results.length; a++){
                        searchmetadata += result.results[a].formatted_address + " *** ";
                    }
                    return {"Location" : result.results[0].formatted_address, "Search_Meta" : searchmetadata}
                }
            })
        },
        radiusupdated(radius){
            let val = this.content.Radius_Unit == 1 ? Math.round(Number(radius * 1609.344)) : Math.round(Number(radius * 1000));
            this.content.Radius = val;
        },
        handleClick(e) {
            const elt = e.target.closest(".update-phone");
            if (elt) {
                this.smsnotification = "";
                this.TogglePopup('buttonTrigger');
            }
        },
        blockClick(e){
            return false;
        },
        reset(){
            this.$router.push('/ourcontents/edit');
            window.location.reload(); 
        },
        onSettingSaved(settings){
            this.content.Expiration_Duration = settings.Duration;
            /* if(settings.selectedDuration == "Custom"){
                this.content.Expiration_Duration = "Custom";
            } */
            //alert(settings.StartDate)
            this.custom_settings = settings;
        },
        onGroupSelectionSaved(groupsData){
           this.selectedGroups = groupsData;
        }
    },
    watch: {
        '$route.query.id':{
            immediate: true,
            handler(id){
                if (id){
                this.edit = 1
                var url = process.env.VUE_APP_APIDOMAIN+'/ourcontents?id='+ id;
                    url+= "&ClientID="+this.$root.userdetail.user[0].ClientID;
                    url+= "&Role="+ this.$root.userdetail.user[0].Role;
                    url+= "&SenderID="+ this.$root.userdetail.user[0].UserID;
                
                fetch(url, { 
                    method: 'GET',
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer "+this.token
                    }  
                })                      
                  .then(response => response.json())
                  .then((result) => {
                    if(result.AuthSuccess == 0){
                        this.serverError = result.message
                        this.$root.logout();           
                    }
                    console.log("result :: ", result)
                   /*  if (!result.content.success){
                        this.$router.push('/messages');
                    } else { */
                    //alwasy open English content id by default
                    //let content = result.content.content;
                    //let message_settings = result.content.message_settings
                        //console.log("result.content :: ", result.content)
                        if (result.content.content.ParentID){
                        this.$router.push('/messages/editor?id='+result.content.content.ParentID);
                        }
                        if (result.content.content.Locale == 'en_US'){
                            result.content.content.Locale = "English";
                        }
                        if (result.content.content.Locale == 'es_US'){
                            result.content.content.Locale = "Spanish";
                        }
                        if (result.content.content.Long != "" && result.content.content.Lat != null && result.content.content.Long != null){
                            this.latlongupdated(result.content.content.Lat, result.content.content.Long);
                            result.content.content.Lat = result.content.content.Lat +','+result.content.content.Long; 
                        } else {
                        result.content.content.Lat = ""; 
                        }
                        this.content = result.content.content;
                        //console.log("result.content = ", result);
                        //if (this.content.Expiration_Duration == "Custom"){
                        if (typeof result.content.content.RepeatOption != "undefined" && result.content.content.RepeatOption != null){
                            //alert(result.content.message_settings.RepeatOption)
                            //this.custom_settings.defaultSettings = false;
                            //this.custom_settings = result.content.message_settings;
                            let custom_settings = {
                                "Duration" : result.content.content.Duration,
                                "StartDate" : result.content.content.StartDate,
                                "EndDate" : result.content.content.EndDate,
                                "RepeatOption" : result.content.content.RepeatOption,
                                "RepeatDays": result.content.content.RepeatDays,
                                "TimeStart" : result.content.content.TimeStart,
                                "TimeEnd" : result.content.content.TimeEnd,
                                "When": result.content.content.When,
                                "defaultSettings" : false
                            }
                            this.custom_settings = custom_settings;
                        }
                        this.custom_settings.Duration = this.content.Expiration_Duration;
                        this.getExpirationTimestamp();
                        //If opening a previous draft, start the data saving triggers 
                        if (this.content.Mode == 1){
                            this.savingDraft = true;
                            let thisRef = this;
                            this.interval = setInterval(thisRef.startDraftMode, 8000);
                        }
                        //console.log("this.content", this.content);
                        //show the settings on open
                        if(typeof this.$route.query.settings != "undefined"){
                            this.showSettingsOnLoad = true;
                        }
                    //}
                                         
                    });
                }              
            }
        },
        
        content : {
            immediate: true,
            handler(spn_Title){
                if (this.content.spn_Title){
                    this.languageObj[1] = { label: 'Spanish', value:"Spanish" }
                }
            }
        }
        
    },
    unmounted(){
      clearInterval(this.interval);
     }
}

</script>


<style lang="scss">
 
.word-count-text {
    padding-left: 13px;
    position: absolute;
    top: 100%;
    color: #7B8794;
    margin-top: 16px;
    font-size: 16px;
    line-height: 1;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.compose-message-page{
    padding-bottom: 60px;
}
    .content-mode{
        font-size:13px;
        border:none;
        position:absolute;
        padding:0;
        color:#757575;
        right: 0;
        top: -3px;
        span{
            display: flex;
            justify-content: flex-end;
            .custom-icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                min-width: 30px;
                height: 20px;
                svg{
                    width: auto;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .white-bg-container {
        margin: 0px;
        padding: 33px 40px 14px 30px !important;
        border-radius: 20px;
        background-color: #fff;
        @media screen and (max-width: 768px) {
            padding: 33px 20px 20px 20px !important;
        }
        @media screen and (max-width: 568px) {
            padding: 33px 20px 20px 20px !important;
        }
    }
   
   
    .formheadings{
        font-weight:500;
        padding-bottom:20px;
    }
    .translation-link{
        margin-top: -1rem;
    }
    .translation-link .disabled {
        cursor: context-menu;
        opacity: .5;
    }
    .btn-link{
        color:#858287  !important;
    }
    .btn-link:hover, .btn-link:visited, .btn-link:focus{
        text-decoration:none  !important;
    }
    .custom-icon-text, .custom-icon{
        vertical-align: middle;
        display: inline-block;
    }
    .custom-icon-text{
        margin-right:5px;
    }
    
    .form-footer-con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top:1px solid #bdbdbd;
        padding-top: 14px;
        min-height: 111px;
        @media screen and (max-width: 568px) {
            flex-wrap: wrap;
            gap: 30px;
            .alert{
                width: 100%;
            }
            .form-btns-con{
                width: 100%;
            }
        }
        .alert-dismissible{
            width: 45.5%;
            margin: 0;
            padding: 15px 48px 15px 16px;
            border: 0;
            border-radius: 10px;
            &.alert-success{
                background: #D6FCC4;
            }
            @media screen and (max-width: 992px) {
                width: 60%;
            }
            @media screen and (max-width: 568px) {
                width: 100%;
            }
        }
        .form-btns-con{
            display: flex;
            gap: 28px;
            justify-content: flex-end;
        }
        .close-btn{
            color: #828282;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            padding: 0;
            letter-spacing: 0;
            &:hover,&:active, &:focus{
                outline: none;
                box-shadow: none;
                border: 0;
            }
            &:disabled{
                opacity: .8;
            }
        }
    }

    .alert-dismissible{
        color: #333;
        font-family: 'Roboto';
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        .alert-heading{
            font-size: 20px;
        }
    }
    
    .form-elements{
        display: flex;
        .map-section{
            width: 50%;
            .form-label{
                margin-bottom: 40px;
            }
        }
        .message-section{
            width: 50%;
            position: relative;
            .section-label-con{
                margin-bottom: 40px;
            }
            .form-group{
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                .form-label{
                    min-width: 90px;
                    margin: 0;
                }
                .form-control{
                    padding: 13px 13px;
                    height: 44px;
                    border-radius: 5px;
                    border: 1px solid #BDBDBD;
                    background: #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &.message-body{
                        height: 170px;
                        @media screen and (max-width: 568px){
                            height: 150px;
                        }
                    }
                }

                &.form-duration-con{
                    margin-top: 43px;
                    margin-bottom: 43px ;
                    .preview-btn-con{
                        min-height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    .preview-btn-space {
                        width: 60px;
                        height: 60px;
                    }
                    .preview-button-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 23px;
                        position: relative;
                        right: -7px;
                        top: -8px;
                        & span {
                            color: #000;
                            text-align: right;
                            font-family: 'Roboto';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;
                            text-transform: none;
                            letter-spacing: 0;
                            opacity: 1;
                            &.disabled-text {
                                color: rgba(#000, 0.4);
                            }
                        }
                    }
                    .preview-btn{
                        color:#344D7A;
                        &:disabled{
                            opacity: .3;
                            pointer-events: none;
                        }
                        &:hover{ 
                            color:rgba(#344D7A, 0.6) !important;
                        }
                    }
                }
            }
            .sms-success-notificaiton{
                border-radius: 10px;
                position: absolute;
                right: 0;
                bottom: 41px;
                display: block;
                overflow: hidden;
                @media screen and (max-width: 568px) {
                    position: relative;
                    bottom: 10px;
                }
                span{
                    display: block;
                    padding: 10px 12px;
                    color:#333;
                    text-align: right;
                    font-family: 'Roboto';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
                span.success-msg{
                    background: #D6FCC4;
                }
                span.error-msg{
                    background: #F5E0AB;
                }
            }
        }
        .form-row{
            display: flex;
            .form-control-con{
                width: 50%;
            }
        }
        .form-label{
            color: #000;
            font-family: 'Roboto';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
        }
        // .form-select{
        //     position: relative;
        //     background: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.845703 0.120728L5.8457 9.71811L10.8457 0.120728L0.845703 0.120728Z' fill='%2349454F'/%3E%3C/svg%3E") no-repeat 94% 50% !important;
        // }
        
        @media screen and (max-width: 992px) {
            flex-wrap: wrap;
            gap: 30px;
            .map-section, .message-section{
                width: 100%;
            }
        }
    }

    .servererror{
        width: 100%;
        background: red;
        color:#fff;
        padding: 15px;
        border-radius: 10px;
    }
    .selected-group-names {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        width: 572px;
        font-size: 16px;
        line-height: 20px;
        & b {
            font-weight: 500;
            flex-shrink: 0;
        }
    }
</style>