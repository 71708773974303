export const config = {
  apiKey: "AIzaSyAEp6HXITijyXBxz5Gn36VXR879JU8xqKY",
  authDomain: "sig2-app.firebaseapp.com",
  projectId: "sig2-app",
  storageBucket: "sig2-app.appspot.com",
  messagingSenderId: "998551770194",
  appId: "1:998551770194:web:696a7d0113d58ff7593b8b",
  measurementId: "G-YPE3XLNP0N"
};


// Configuration for sig2 firebase auth account
/* 
export const config = {
  apiKey: "AIzaSyDk7HWbc-GGj4D731X-PWx6ZY99leqLjl4",
  authDomain: "sig2app.firebaseapp.com",
  projectId: "sig2app",
  storageBucket: "sig2app.appspot.com",
  messagingSenderId: "294258571306",
  appId: "1:294258571306:web:61804c908dc63db7ea6b53",
  measurementId: "G-Y2HMHVYE04"
}; */