<template>
  <!-- <div class="router-view-wrapper" v-if="showlogin || otherPages"> -->
    <div class="router-view-wrapper" v-if="showlogin || otherPages">
    <div class="landing-page app-container d-flex justify-content-between">
      <div class="landing-text-section" v-if="!overlayPages">
        <h2 class="font-roboto text-primary">Right Place. Right Time.</h2>
        <h4 class="font-roboto text-primary fw-medium ">Geofence Text-to-Speech Messages</h4>
      </div>
      <div class="landing-login-form-section" v-if="!overlayPages">
        <AuthHome @btnClick="handleBtnClick" />
      </div>
      <!-- <div v-else class="landing-login-form-section">
        <Auth/>
      </div> -->
    </div>
    <Footer :classValue="'home-footer'"/>
  </div>

  <div v-if="!showlogin && !otherPages && afterloginpage" style="width: 100%; height: 100%; position: fixed; top:0px; left:0px; background: #f2f2f2;">
    
    <Header :user="''"/>
  </div>
</template>

<script>
//import Auth from '../components/Auth.vue'
import AuthHome from '../components/Home.vue'
import Footer from '../components/common/Footer.vue'
import Header from '../components/common/Header.vue'
import { ref } from 'vue';
export default {
  name: 'HomeView',
  components: {AuthHome, Footer,Header },
  data() {
    return {
      showlogin: false,
      showAuthHome: true,
      otherPages:false,
      afterloginpage:false
    }
	},
  methods: {
    handleBtnClick(action) {
      window.location.href = '/login';
    }
  },
  created(){

    setTimeout(()=>{
      this.showlogin = window.location.pathname == "/";
      this.otherPages = window.location.pathname.indexOf('/terms') != -1 ||
                        window.location.pathname.indexOf('/privacy') != -1 ||
                        window.location.pathname.indexOf('/logout') != -1 ||
                        window.location.pathname.indexOf('/authnotallowed') != -1;
      this.overlayPages = window.location.pathname.indexOf('/terms') != -1 ||
                        window.location.pathname.indexOf('/privacy') != -1;
      this.afterloginpage = true;                
      //alert(ref(this.$router.currentRoute).value.path)
      //ref(this.$router.currentRoute).value.path == '/' 
      //this.otherPages = ref(this.$router.currentRoute).value.path == '/terms' ||
        //               ref(this.$router.currentRoute).value.path == '/privacy'
    }, 10)
  }
}
</script>

<style lang="scss" scoped>

.router-view-wrapper{
    min-height: 100vh;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.landing-page{
  padding-top: 70px;
  padding-bottom: 70px;
  @media screen and (max-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: column;
    min-height: max-content;
    gap: 30px;
  }
  @media screen and (max-width: 568px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .landing-text-section{
    h2{
      font-size: 64px;
      line-height: 72px;
      font-weight: 800;
      @media screen and (max-width: 1280px) {
        font-size: clamp(28px,5vw, 56px);
      }
    }
    h4{
      font-size: 42px;
      line-height: 72px;
      @media screen and (max-width: 1440px) {
        font-size: 42px;
      }
      @media screen and (max-width: 1280px) {
        font-size: clamp(18px, 3.2vw, 40px);
      }
    }
  }
}
</style>