<template>
  <v-app id="app">	
      <div>   
        <Header :user="loggedInUser"/>
        <div class="app-container full-screen">
          <router-view :user="loggedInUser"></router-view>
        </div>
      </div>
      <div class="app-container">
        <Footer/>	
      </div>
  </v-app>
</template>


<script>
//import VueMaterial from 'vue-material'
//import 'vue-material/dist/vue-material.min.css'
//import 'vue-material/dist/theme/default.css'
import Header from './components/common/Header.vue';
import Footer from './components/common/Footer.vue';
import './assets/icon.css';





export default {
    props:["loggedInUser"],
    components: {Header, Footer},
    name: 'app',
    methods: {
        isPath (path) {
           return this.$route.path === path
        }
    }
}
</script>

<style scoped>
@font-face {
    font-family: Roboto;
    src: url('assets/fonts/Roboto-Regular.ttf');
}
.v-btn{
  text-transform:none !important;
  
}
body .v-application{
    background:#f2f2f2 !important;
}
.gray-bg{
  background:#f2f2f2;
}








</style>
