<template>
  <div>
    <template v-if="isGroup">
      <div class="position-relative" v-if="showInfo">
        <div class="w-100 position-absolute info_panel_msg">
          <InfoPanel @close="showInfo = false" :panelClass="'warning_panel'">
            <p><strong>Confirmation: </strong>a group has been deleted.</p>
          </InfoPanel>
        </div>
      </div>
      <S2gTable
        :indeterminate="indeterminate"
        :items="groups"
        :headers="groupListHeader"
        :isWhiteIcons="true"
        :filterItems="[
          { label: 'All', value: 'all' },
          { label: 'None', value: 'none' },
        ]"
        @search="search"
        @column-filter="handleFilter"
        @toggle-row-select="toggleRowChecked"
        @refresh="reloadTableData"
        @select-all="selectAll"
        :totalItems="groupPagination.pager.totalItems"
        :totalCountText="'Groups'"
        :totalCountText1="'Group'"
        :showCount="true"
        :isSelectGlobalCheckBox="isSelectAll"
        :page="(groupPagination.currentPage - 1) * groupPagination.itemsPerPage"
        :disabledTable="!disabledTable"
      >
        <template v-slot:table_body="{ item }">
          <td>
            <span class="fixed-length-text pr-8" style="max-width: 173px">{{ item.item.Name }}</span>
          </td>
          <td>
            <div class="filter-cell">
              <span class="svg_icon">
              <component :is="item.item.Icon"></component>
            </span>
            {{ item.item.Group_Type }}
            </div>
          </td>
          <td class="text-right">{{ item.item.Member_Count }}</td>

          <td>
            <div class="filter-cell">
              <span></span>
              <div class="flex items-center">
                <SwitchOn
                class="cursor_pointer switch-icon-btn"
                v-if="item.item.Status == 'active'"
                @clicked="(e) => updateGroupStatus(e, item.item, 'inactive')"
                :model="item.item.Status == 'active'"
                />
                <SwitchOffInactive
                  class="cursor_pointer switch-icon-btn"
                  v-if="item.item.Status == 'inactive'"
                  @clicked="(e) => updateGroupStatus(e, item.item, 'active')"
                  :model="item.item.Status == 'active'"
                />
              </div>
            </div>
          </td>
          <td>
            <div class="table-actions">
              <span @click="showGroupMembers(item.item)">
                <EditIcon class="icon-btn" />
              </span>
              <span class="" @click="showDeletePopup(item.item)">
                <DeleteIcon class="icon-btn" />
              </span>
            </div>
          </td>
        </template>
      </S2gTable>
      <div class="ma-10">
        <Paginaiton
          v-if="groupPagination.pager.totalPages > 1"
          :currentPage="groupPagination.currentPage"
          :totalItems="groupPagination.pager.totalItems"
          :itemsPerPage="groupPagination.itemsPerPage"
          :totalPages="groupPagination.pager.totalPages"
          :disabled="!disabledTable"
          @page-changed="changePage"
        />
      </div>
      <GroupMemberDialog
        @close-dialog="closeDialog"
        :group="group"
        :groupTypesList="groupTypesList"
        v-if="dialog"
      />
    </template>
    <Confirmation
      v-model="confirmDialog"
      :data="group"
      @closed="confirmDialog = false"
      :onconfirm="(data) => confirmRemove()"
      :body="'Do you really want to delete this group?'"
    />
  </div>
</template>
<script >
const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
// import Switch from "@/components/common/Switch.vue";
import SwitchOn from "@/components/common/SwitchOn.vue";
import InfoPanel from "@/components/common/InfoPanel.vue";
// import SwitchOff from "@/components/common/SwitchOff.vue";
import SwitchOffInactive from "@/components/common/SwitchOffInactive.vue";
import EditIcon from "@/components/icons/EditIcon";
import DeleteIcon from "@/components/icons/DeleteIcon";
import CompanyIcon from "@/components/icons/Company";
import GroupIcon from "@/components/icons/Group";
import EventIcon from "@/components/icons/Event";
import PropertyIcon from "@/components/icons/Property";
import AreaIcon from "@/components/icons/Area";
import TeamIcon from "@/components/icons/MemberIcon";
import ProjectIcon from "@/components/icons/Project";
import LocationIcon from "@/components/icons/Location";
import S2gTable from "@/components/common/S2gTable.vue";
import GroupMemberDialog from "@/components/group/GroupMemberDialog.vue";
import Paginaiton from "@/components/common/Paginaiton.vue";
import Confirmation from "@/components/modals/Confirmation.vue";

import {
  manageUnSelectedItems,
  manageSelectedItems,
  manageSingleChecked,
  manageRowDefaultSelection,
} from "@/helpers/s2gtable-helper";

export default {
  props: {
    groupTypesList: [],
    disabledTable:Boolean,
  },
  components: {
    // Switch,
    SwitchOn,
    SwitchOffInactive,
    // SwitchOff,
    EditIcon,
    DeleteIcon,
    S2gTable,
    GroupMemberDialog,
    Paginaiton,
    Confirmation,
    CompanyIcon,
    GroupIcon,
    EventIcon,
    ProjectIcon,
    PropertyIcon,
    LocationIcon,
    TeamIcon,
    AreaIcon,
    InfoPanel,
  },
  data() {
    return {
      groupListHeader: [
        { key: "name", title: "Name" },
        {
          key: "Group_Type_Id",
          title: "Type",
          width: "30%",
          isFilter: true,
          filterItems: [],
        },
        { key: "total_member", title: "" },
        {
          key: "status",
          title: "Active",
          width: "15%", 
          isFilter: true,
          filterItems: [
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
          ],
        },
        { key: "action", title: "",  width: "15%", },

      ],
      groups: [],
      group: {},
      isGroup: false,
      confirmDialog: false,
      indeterminate: false,
      isSelectAll: false,
      showInfo: false,
      searchText: "",
      selectedItem: 0,
      token: localStorage.getItem("tknds") || "",
      groupPagination: {
        currentPage: 1,
        pager: { totalPages: 0, totalItems: 0 },
        itemsPerPage: 5,
      },
      groupFilter: { Group_Type_Id: null,status:undefined },
      uncheckedItems: [],
      checkedItems: [],
    };
  },
  methods: {
    getGroups() {
      let url =
        API_URL +
        "groups/?page=" +
        this.groupPagination.currentPage +
        "&perpage=" +
        this.groupPagination.itemsPerPage +
        "&search=" +
        this.searchText;
      url += "&ClientID=" + this.$root.userdetail.user[0].ClientID;
      url += "&CreatedBy=" + this.$root.userdetail.user[0].UserID;
      url += "&Role=" + this.$root.userdetail.user[0].Role;
      if (this.groupFilter.Group_Type_Id) {
        url += "&Group_Type_Id=" + this.groupFilter.Group_Type_Id;
      }
      if (this.groupFilter.status) {
        url += "&status=" + this.groupFilter.status;
      }
      fetch(url, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(this.uncheckedItems, this.checkedItems);
          //Auth Token Failed
          if (result.AuthSuccess == 0) {
            this.serverError = result.message;
            this.$root.logout();
          } else {
            this.groups = result.data?.map((e) => {
              e.checked = e.checked = manageRowDefaultSelection(
                this.isSelectAll,
                e,
                this.checkedItems,
                this.uncheckedItems,
                "Id"
              );
              e.class = e.Status == "inactive" ? "hover_item danger_text" : "hover_item";
 
              e.checkdClass = e.checked ? "member_selected" : "";
              if(e.Default_Group == "1"){
                //e.class = "disabled"
                e.disable_checkbox = e.Default_Group;
              }

              return e;
            });
            if (this.groups.length > 0) {
              this.isGroup = true;
            }
            this.groupPagination.pager = result.pager;
          }
          this.formsubmitwait = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    reloadTableData(e) {
      this.groups = [];
      this.group = {};
      this.confirmDialog = false;
      this.indeterminate = false;
      this.isSelectAll = false;
      this.showInfo = false;
      this.searchText = "";
      this.selectedItem = 0;
      this.groupFilter = { Group_Type_Id: null };
      this.uncheckedItems = [];
      this.checkedItems = [];
      this.getGroups();
    },
    toggleRowChecked(e) {
      const event = e.e;

      //update checked property
      const u = manageSingleChecked(
        event,
        e.item,
        "Id",
        this.groups,
        this.selectedItem,
        this.isSelectAll,
        this.groupPagination.totalItems
      );
      this.selectedItem = u.selectedCounts;
      this.indeterminate = u.indeterminate;
      this.isSelectAll = u.isSelectAll;
      if (this.selectedItem == 0) {
        this.indeterminate = false;
      }
      this.manageClass()
      manageSelectedItems(event, e.item, "Id", this.checkedItems);
      manageUnSelectedItems(event, e.item, "Id", this.uncheckedItems);
    },
    manageClass(){
      this.groups = this.groups.map((e) => {
        if (e.Default_Group == 1){
          return e; 
        }
        e.class = e.Status == "inactive" ? "hover_item danger_text" : "hover_item";
        e.checkdClass = e.checked ? "member_selected" : "";
        
        return e;
      });
    },
    selectAll(event) {
      this.checkedMember = [];
      this.uncheckedMembers = [];
      this.isSelectAll = event.target.checked;
      this.indeterminate = false;
      this.groups = this.groups.map((e) => {
        e.checked = e.Default_Group != "1" && event.target.checked;
        return e;
      });
      this.manageClass()
      if (this.isSelectAll) {
        this.selectedItem = this.groupPagination.totalItems;
      }
    },
    search(val) {
      this.searchText = val;
      this.getGroups();
    },
    selectGroupType(e) {
      console.log(e);
      this.groupData.groupType = e.value;
    },
    handleFilter(e) {
      console.log(e);
      try{
      const event = e.e;
      if (!e.column) {
        if (event.value == "all") {
          this.selectAll({ target: { checked: true } });
        } else {
          this.selectAll({ target: { checked: false } });
        }
      } else {
        this.groupPagination = {
          currentPage: 1,
          pager: { totalPages: 0, totalItems: 0 },
          itemsPerPage: 5,
        };
        console.log(e.column.key)
        this.groupFilter[e.column.key] = e.e.value;
        console.log('000', this.groupFilter)
        this.getGroups();
      }
       }catch(e){
        console.log(e)
      }
    },
    showGroupMembers(item) {
      this.group = item;
      this.dialog = true;
      console.log(item);
      this.$forceUpdate();
    },
    closeDialog() {
      this.group = null;
      this.getGroups();
      this.dialog = false;
    },
    showDeletePopup(item) {
      console.log("saas", item);
      this.member_id = item.Id;
      this.confirmDialog = true;
      this.$forceUpdate();
    },
    confirmRemove() {
      const API_URL = process.env.VUE_APP_APIDOMAIN + "/";
      //  this.conversations = JSON.parse(item);
      fetch(`${API_URL}delete_group?id=${this.member_id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.reloadTableData();
          this.getGroups();

          this.showInfo = true;
          this.confirmDialog = false;
          setTimeout((e) => {
            this.showInfo = false;
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changePage(page) {
      this.groupPagination.currentPage = page;
      this.getGroups();
    },
    updateGroupStatus(e, val, status) {
      console.log(val);
      val.loading = true;
      fetch(API_URL + "update_group_status", {
        method: "PUT",
        body: JSON.stringify({
          // status: e.target.checked ? "active" : "inactive",
          status: status,
          id: val.Id,
        }),
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          val.loading = false;

          this.getGroups();
          console.log(result);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  mounted() {
    this.getGroups();
    //console.log("=============", this.groupListHeader, this.groupTypesList);
    this.groupListHeader = this.groupListHeader.map((e) => {
      if (e.isFilter && e.key == "Group_Type_Id") {
        e.filterItems = this.groupTypesList;
      }
      return e;
    });
    console.log('----', this.groupListHeader)
  },
};
</script>
 <style scoped lang="scss">
  
 
::v-deep {
  .danger_text {
    td {
      color: #b10000;
      &:not(:first-child) {
        .svg_icon{
        svg {
          path,
          rect {
            stroke: #b10000;
          }
        }
        }
      }
    }
  }
  .warning_panel {
    background: #f5e0ab;
  }
}
.info_panel_msg {
  top: 1px;
}

.switch-icon-btn{
  line-height: 0;
  display: inline-flex;
  align-items: center;
}
 
</style>